.col {
  align-self: stretch;
  flex: calc(100% - 16px);
  max-width: calc(100% - 16px);
  min-width: calc(99.9999% / 12 - 16px);
  margin: 16px 8px 0 8px;
  overflow-wrap: break-word;
  transition: max-width 300ms cubic-bezier(0.4, 0, 0.2, 1); }

.col-sm-1 {
  flex: 1 0 calc(99.9999% / 12 * 1 - 16px);
  max-width: calc(99.9999% / 12 * 1 - 16px); }

.col-sm-2 {
  flex: 1 0 calc(99.9999% / 12 * 2 - 16px);
  max-width: calc(99.9999% / 12 * 2 - 16px); }

.col-sm-3 {
  flex: 1 0 calc(99.9999% / 12 * 3 - 16px);
  max-width: calc(99.9999% / 12 * 3 - 16px); }

.col-sm-4 {
  flex: 1 0 calc(99.9999% / 12 * 4 - 16px);
  max-width: calc(99.9999% / 12 * 4 - 16px); }

.col-sm-5 {
  flex: 1 0 calc(99.9999% / 12 * 5 - 16px);
  max-width: calc(99.9999% / 12 * 5 - 16px); }

.col-sm-6 {
  flex: 1 0 calc(99.9999% / 12 * 6 - 16px);
  max-width: calc(99.9999% / 12 * 6 - 16px); }

.col-sm-7 {
  flex: 1 0 calc(99.9999% / 12 * 7 - 16px);
  max-width: calc(99.9999% / 12 * 7 - 16px); }

.col-sm-8 {
  flex: 1 0 calc(99.9999% / 12 * 8 - 16px);
  max-width: calc(99.9999% / 12 * 8 - 16px); }

.col-sm-9 {
  flex: 1 0 calc(99.9999% / 12 * 9 - 16px);
  max-width: calc(99.9999% / 12 * 9 - 16px); }

.col-sm-10 {
  flex: 1 0 calc(99.9999% / 12 * 10 - 16px);
  max-width: calc(99.9999% / 12 * 10 - 16px); }

.col-sm-11 {
  flex: 1 0 calc(99.9999% / 12 * 11 - 16px);
  max-width: calc(99.9999% / 12 * 11 - 16px); }

.col-sm-12 {
  flex: 1 0 calc(99.9999% / 12 * 12 - 16px);
  max-width: calc(99.9999% / 12 * 12 - 16px); }

@media (min-width: 768px) {
  .col-md-1 {
    flex: 1 0 calc(99.9999% / 12 * 1 - 16px);
    max-width: calc(99.9999% / 12 * 1 - 16px); }
  .col-md-2 {
    flex: 1 0 calc(99.9999% / 12 * 2 - 16px);
    max-width: calc(99.9999% / 12 * 2 - 16px); }
  .col-md-3 {
    flex: 1 0 calc(99.9999% / 12 * 3 - 16px);
    max-width: calc(99.9999% / 12 * 3 - 16px); }
  .col-md-4 {
    flex: 1 0 calc(99.9999% / 12 * 4 - 16px);
    max-width: calc(99.9999% / 12 * 4 - 16px); }
  .col-md-5 {
    flex: 1 0 calc(99.9999% / 12 * 5 - 16px);
    max-width: calc(99.9999% / 12 * 5 - 16px); }
  .col-md-6 {
    flex: 1 0 calc(99.9999% / 12 * 6 - 16px);
    max-width: calc(99.9999% / 12 * 6 - 16px); }
  .col-md-7 {
    flex: 1 0 calc(99.9999% / 12 * 7 - 16px);
    max-width: calc(99.9999% / 12 * 7 - 16px); }
  .col-md-8 {
    flex: 1 0 calc(99.9999% / 12 * 8 - 16px);
    max-width: calc(99.9999% / 12 * 8 - 16px); }
  .col-md-9 {
    flex: 1 0 calc(99.9999% / 12 * 9 - 16px);
    max-width: calc(99.9999% / 12 * 9 - 16px); }
  .col-md-10 {
    flex: 1 0 calc(99.9999% / 12 * 10 - 16px);
    max-width: calc(99.9999% / 12 * 10 - 16px); }
  .col-md-11 {
    flex: 1 0 calc(99.9999% / 12 * 11 - 16px);
    max-width: calc(99.9999% / 12 * 11 - 16px); }
  .col-md-12 {
    flex: 1 0 calc(99.9999% / 12 * 12 - 16px);
    max-width: calc(99.9999% / 12 * 12 - 16px); } }

@media (min-width: 992px) {
  .col-lg-1 {
    flex: 1 0 calc(99.9999% / 12 * 1 - 16px);
    max-width: calc(99.9999% / 12 * 1 - 16px); }
  .col-lg-2 {
    flex: 1 0 calc(99.9999% / 12 * 2 - 16px);
    max-width: calc(99.9999% / 12 * 2 - 16px); }
  .col-lg-3 {
    flex: 1 0 calc(99.9999% / 12 * 3 - 16px);
    max-width: calc(99.9999% / 12 * 3 - 16px); }
  .col-lg-4 {
    flex: 1 0 calc(99.9999% / 12 * 4 - 16px);
    max-width: calc(99.9999% / 12 * 4 - 16px); }
  .col-lg-5 {
    flex: 1 0 calc(99.9999% / 12 * 5 - 16px);
    max-width: calc(99.9999% / 12 * 5 - 16px); }
  .col-lg-6 {
    flex: 1 0 calc(99.9999% / 12 * 6 - 16px);
    max-width: calc(99.9999% / 12 * 6 - 16px); }
  .col-lg-7 {
    flex: 1 0 calc(99.9999% / 12 * 7 - 16px);
    max-width: calc(99.9999% / 12 * 7 - 16px); }
  .col-lg-8 {
    flex: 1 0 calc(99.9999% / 12 * 8 - 16px);
    max-width: calc(99.9999% / 12 * 8 - 16px); }
  .col-lg-9 {
    flex: 1 0 calc(99.9999% / 12 * 9 - 16px);
    max-width: calc(99.9999% / 12 * 9 - 16px); }
  .col-lg-10 {
    flex: 1 0 calc(99.9999% / 12 * 10 - 16px);
    max-width: calc(99.9999% / 12 * 10 - 16px); }
  .col-lg-11 {
    flex: 1 0 calc(99.9999% / 12 * 11 - 16px);
    max-width: calc(99.9999% / 12 * 11 - 16px); }
  .col-lg-12 {
    flex: 1 0 calc(99.9999% / 12 * 12 - 16px);
    max-width: calc(99.9999% / 12 * 12 - 16px); } }
